import { Navigate, useParams } from '@solidjs/router';
import { Icon } from '@troon/icons';
import {
	ActivityIndicator,
	Button,
	Container,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	Link,
	LinkButton,
	Page,
	Section,
} from '@troon/ui';
import { createSignal, Show, Suspense, useContext } from 'solid-js';
import { ReservationCtx } from '../../../providers/reservation';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import { useUser } from '../../../providers/user';
import { ReservationState } from '../../../graphql';
import { UpsellAccessSection } from '../../../components/upsells/access';
import { AccessProducts } from '../../../components/access-products';
import { InvitePlayers } from './components/invite-players';
import { InvitePlayersInfo } from './components/invite-players-info';

export default function ReservationConfirmation() {
	const params = useParams<{ reservationId: string }>();
	const data = useContext(ReservationCtx)!;
	const user = useUser();
	const [showUpsellDialog, setShowUpsellDialog] = createSignal(false);

	return (
		<Suspense fallback={<ActivityIndicator class="size-16 text-brand">Confirming your Reservation</ActivityIndicator>}>
			<Show
				when={
					!!(
						data()?.reservation.ownerId === user()?.me.id &&
						data()?.reservation.state !== ReservationState.Cancelled &&
						!data()?.reservation.isPastStartTime &&
						(data()?.reservation.playerCount ?? 0) > 1
					)
				}
				fallback={<Navigate href={`/reservation/${params.reservationId}`} />}
			>
				<Container size="small">
					<Page class="gap-8 md:gap-12">
						<Section>
							<Icon name="circle-check-custom" class="size-24 md:size-32" />
							<Heading as="h1">Your tee time is confirmed!</Heading>
							<Suspense>
								<Show when={(data()?.reservation.playerCount ?? 0) > 1}>
									<InvitePlayersInfo />
								</Show>
							</Suspense>
						</Section>

						<Suspense>
							<Section layout="tight" class="rounded border border-neutral p-4 md:p-6">
								<Heading as="h2" size="h4" class="flex items-center gap-2">
									<Icon name="user-add" class="text-brand" />
									<span>Invite Players</span>
								</Heading>

								<InvitePlayers showRecentInvited />
							</Section>
						</Suspense>

						<Suspense>
							<Show when={data()?.accessUpsell}>
								{(upsell) => (
									<UpsellAccessSection
										layout="compact"
										location="reservationConfirmation"
										withPhoto={false}
										class="cursor-pointer"
									>
										<div class="absolute inset-0" onClick={() => setShowUpsellDialog(true)} />
										<p class="text-2xl font-semibold md:text-3xl">
											Save {upsell().savings.displayValue} on this tee time right now!
										</p>
										<p>Join Troon Access to save on this round and every round you play at over 150 courses.</p>
										<Dialog key="access-products" onOpenChange={setShowUpsellDialog} open={showUpsellDialog()}>
											<DialogTrigger class="relative z-10 size-fit">
												Save now <Icon name="arrow-right-md" />
											</DialogTrigger>
											<DialogContent
												autoWidth
												header={<>Save {upsell().savings.displayValue} on this tee time</>}
												headerLevel="h2"
											>
												<div class="flex flex-col items-center gap-4">
													<AccessProducts />
													<LinkButton href="/access" class="size-fit">
														Learn more about Troon Access <Icon name="arrow-right-md" />
													</LinkButton>
												</div>
											</DialogContent>
										</Dialog>
									</UpsellAccessSection>
								)}
							</Show>
						</Suspense>

						<Section class="items-center text-center">
							<Button as={Link} href={`/reservation/${params.reservationId}`} appearance="secondary">
								Continue to Reservation <Icon name="arrow-right-md" />
							</Button>
						</Section>

						<Suspense>
							<Show when={(data()?.reservation.playerCount ?? 0) > 1}>
								<Section>
									<Heading as="h2" size="h3">
										Frequently Asked Questions
									</Heading>
									<div class="rounded border border-neutral">
										<FrequentlyAskedQuestions id="invite-users-faq" />
									</div>
								</Section>
							</Show>
						</Suspense>
					</Page>
				</Container>
			</Show>
		</Suspense>
	);
}

export const route = { info: { hasHero: false } };
